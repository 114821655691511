<template>
  <div class="category-main-slider mb-5">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="subCategory in validCategories"
        :key="subCategory.categoryId"
      >
        <v-card
          elevation="0"
          flat
          :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
        >
          <v-img
            :src="src(subCategory)"
            :alt="subCategory.name"
            contain
            onerror="this.onerror=null;this.src='/no-icon.png'"
            width="200"
            height="200"
          />
          <h2 class="text--secondary subcategory-name">
            {{ subCategory.name }}
          </h2>
        </v-card>
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && categories.length > 1"
      :id="`pagination-${paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="showArrows && categories.length > 1"
      :id="`slider-prev-${paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="showArrows && categories.length > 1"
      :id="`slider-next-${paginationClass}`"
      class="swiper-button-next"
    ></div>
  </div>
</template>

<style lang="scss">
.category-main-slider {
  .swiper-container {
    height: 255px;
  }
  .swiper-slide {
    width: initial;
  }
  .swiper-pagination-firstCategory {
    bottom: -20px;
    display: flex;
    /* flex-direction: column; */
    position: relative;
    justify-content: center;
  }

  .v-card {
    width: 220px;
    // height: 220px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .v-card__title {
      color: var(--v-default-base);
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      word-break: keep-all;
      padding-left: 0px;
      padding-right: 0px;
    }
    .subcategory-name {
      font-size: 18px;
      line-height: 1;
      // height: 60px;
      align-items: flex-start;
      text-align: center;
      word-break: keep-all;
      padding-left: 0px;
      padding-right: 0px;
      font-weight: bold;
    }
  }
}
.category-main-slider {
  position: relative;
}
</style>

<script>
export default {
  name: "CategoryMainSlider",
  props: {
    categories: { type: Array, required: true, default: () => [] },
    paginationClass: { type: String, default: "firstCategory" },
    cols: { type: Number, default: 1 },
    sm: { type: Number, default: 1 },
    md: { type: Number, default: 1 },
    lg: { type: Number, default: 1 },
    showArrows: { type: Boolean, default: true },
    showBullets: { type: Boolean, default: true }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: `#pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#slider-prev-${this.paginationClass}`,
          nextEl: `#slider-next-${this.paginationClass}`
        }
      }
    };
  },
  computed: {
    validCategories() {
      return this.categories && this.categories.filter(cat => cat.slug);
    }
  },
  methods: {
    src(subCategory) {
      return subCategory.metaData?.category_info?.THUMB;
    }
  }
};
</script>
