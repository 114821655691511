<template>
  <div class="category-main">
    <Breadcrumb :items="breadcrumb" />
    <ebsn-style :target="category" path="category_advanced.CSS_STYLE" />
    <category-block
      :target="category"
      :category-id="category.categoryId"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container class="category-header">
      <ebsn-meta
        :target="category"
        path="category_info.TITLE"
        :default-value="category.name"
        tag="h1"
        :style="{ color: $ebsn.meta(category, 'category_info.COLOR') }"
      ></ebsn-meta>

      <v-img
        :src="$ebsn.meta(category, 'category_info.HEADER_IMAGE')"
        contain
        onerror="this.onerror=null;this.src='/no-icon.png'"
        width="100%"
      >
      </v-img>
      <ebsn-meta
        class="SUBTITLE"
        :target="category"
        path="category_info.SUBTITLE"
        tag="h3"
        @click="clicked"
      ></ebsn-meta>
      <ebsn-meta
        class="description"
        :target="category"
        path="category_info.DESCRIPTION"
        tag="p"
        @click="clicked"
      ></ebsn-meta>
    </v-container>
    <v-container>
      <CategoryMainSlider
        :categories="category.children"
        :key="category.categoryId"
        paginationClass="subcategory"
      />
    </v-container>
    <category-block
      :target="category"
      :category-id="category.categoryId"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      :category-id="category.categoryId"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.category-main {
  .center-img {
    margin-right: auto;
    margin-left: auto;
  }
  .category-header {
    h3 {
      line-height: 0.9;
      line-height: 1.2;
    }
    .category-description {
      position: absolute;
      right: 20px;
      background: #fff;
      top: 40px;
      width: 45%;
      padding: 30px;
    }
  }
}
</style>
<script>
// @ is an alias to /src
// import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins, clickHandler],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      count: -1
    };
  },
  components: {
    // CategoryTitle,
    CategoryMainSlider,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.$t("meta.category.firstLevel.title", [this.category.name])
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      this.$t("meta.category.firstLevel.description", [this.category.name])
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            this.category.metaData?.category_info?.HEADER_IMAGE ||
            this.category.metaData?.category_info?.DESCRIPTION_IMAGE ||
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Zona.eu"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
